.app {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (min-height: 660px) {
  //noinspection CssOverwrittenProperties
  .app {
    height: 100vh;
    //noinspection CssInvalidPropertyValue
    height: 100dvh;
  }
}
