.root {
  max-width: 360px;
  width: 100%;
  padding: 16px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;
}

.id {
  padding: 8px;
  border: 1px solid var(--text-primary);
  border-radius: 64px;
  font-size: 18px;
  font-weight: 500;
}
