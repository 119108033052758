.bg-transition,
.bg-transition * {
  transition: background-color 0.3s;
}

:root .primary-button {
  --button-blue: var(--violet);
  --buton-blue-hover: var(--violet);
  --button-blue-active: var(--violet);
  border-radius: 16px;
  flex-shrink: 0;
}

:root .secondary-button {
  --button-lightblue: var(--violet-bg);
  --button-lightblue-hover: var(--violet-bg);
  --button-lightblue-active: var(--violet-bg);
  --text-blue: var(--violet);
  flex-shrink: 0;
}

:root .button-white {
  --button-blue: var(--button-white);
  --buton-blue-hover: var(--button-white);
  --button-blue-active: var(--button-white);
  --text-white: var(--text-secondary);
  border-radius: 16px;
  flex-shrink: 0;
}

::-webkit-scrollbar-thumb {
  background-color: var(--violet);
  height: 90%;
  padding-top: 20px;
}

:root .button-sm {
  height: 40px;
  border-radius: 8px;
}

.light {
  --bg-booking-enabled: #a492ff;
  --bg-payment-enabled: #FE9D67;
}

.dark {
  --bg-booking-enabled: #382c77;
  --bg-payment-enabled: #7C3812;
}

link:active {
  color: inherit;
}

.radius-16 {
  border-radius: 16px;
}

.SmartCaptcha-Shield {
  z-index: 100;
}

.SmartCaptcha-Shield_position_top {
  top: 5% !important;
}

@keyframes full-slide-from-right {
  from {
    transform: translateX(100%);
  }
}

@keyframes full-slide-to-left {
  to {
    transform: translateX(-100%);
  }
}

@keyframes full-slide-from-left {
  to {
    transform: translateX(100%);
  }
}

@keyframes full-slide-to-right {
  from {
    transform: translateX(-100%);
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
}

@keyframes fade-out {
  to {
    opacity: 0;
  }
}

@keyframes slide-from-right {
  from {
    transform: translateX(180px);
  }
}

@keyframes slide-to-left {
  to {
    transform: translateX(-180px);
  }
}

@keyframes slide-from-left {
  to {
    transform: translateX(180px);
  }
}

@keyframes slide-to-right {
  from {
    transform: translateX(-180px);
  }
}

//noinspection CssInvalidPseudoSelector
::view-transition-old(root) {
  animation: 300ms both full-slide-to-left;
}

//noinspection CssInvalidPseudoSelector
::view-transition-new(root) {
  animation: 300ms both full-slide-from-right;
}

//noinspection CssInvalidPseudoSelector
.back-transition::view-transition-old(root) {
  animation-name: full-slide-from-left;
}

//noinspection CssInvalidPseudoSelector
.back-transition::view-transition-new(root) {
  animation-name: full-slide-to-right;
}

@media (min-width: 800px) {
  //noinspection CssInvalidPseudoSelector
  ::view-transition-old(root) {
    animation:
      90ms cubic-bezier(0.4, 0, 1, 1) both fade-out,
      300ms cubic-bezier(0.4, 0, 0.2, 1) both slide-to-left;
  }

  //noinspection CssInvalidPseudoSelector
  ::view-transition-new(root) {
    animation:
      210ms cubic-bezier(0, 0, 0.2, 1) 90ms both fade-in,
      300ms cubic-bezier(0.4, 0, 0.2, 1) both slide-from-right;
  }

  //noinspection CssInvalidPseudoSelector
  .back-transition::view-transition-old(root) {
    animation-name: fade-out, slide-from-left;
  }

  //noinspection CssInvalidPseudoSelector
  .back-transition::view-transition-new(root) {
    animation-name: fade-in, slide-to-right;
  }
}
